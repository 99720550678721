import gql from "graphql-tag";

export const BookingRequests = {
    Queries: {
        BookingRequest: gql`
        query bookingRequest($id: ID) {
            bookingRequest(id: $id) {
                Id
                Title
                FirstName
                LastName
                Street
                ZipCode
                City
                Email
                Phone
                Country
                VoucherId
                BirthDate
                CreatedAt
            }
        }`,
        BookingRequestByVoucherId: gql`
        query bookingRequestByVoucherId($voucherId: ID) {
            bookingRequestByVoucherId(voucherId: $voucherId) {
                Id
                Title
                FirstName
                LastName
                Street
                ZipCode
                City
                Email
                Phone
                Country
                VoucherId
                BirthDate
                CreatedAt
            }
        }`,
    },
    Mutations: {
        SaveBookingRequest: gql`
        mutation saveBookingRequest($bookingRequest: BookingRequestsInput, $vouchers: [VouchersInput], $bookingTicket: BookingTicketsInput, $bookingDateRequests: [BookingDateRequestsInput]) {
            saveBookingRequest(bookingRequest: $bookingRequest, vouchers: $vouchers, bookingTicket: $bookingTicket, bookingDateRequests: $bookingDateRequests) {
                IsSuccess
                Message
                StringData
            }
        }`,
    }
};
