<template lang="pug">
.row.mt-5
  .col-12.text-center
    .background-circle
      .fa-4x(style="color:#fff").align-self-center
        font-awesome-icon(:icon="['fas', 'calendar-alt']")
    h2.mt-3 {{ $t("booking-can-be-booked.headline") }}
    h4 {{ $t("constants.headline") }} {{ voucher.Id }}

  .col-12.mt-3
    .table-responsive
      table.table.table-borderless.d-table.border
        thead.table-head
          tr
            th(scope="col")
              b {{ $t("constants.ticketNumber") }}&nbsp;{{ bookingTicket.Id }}
            th(scope="col")
              b {{ $t("constants.requestedOn") }}&nbsp;{{ bookingTicket.CreatedAt | formatDate }}
            th(scope="col")
              b(v-if="parentVoucher") {{ $t("constants.mainVoucher") }}:&nbsp;{{ parentVoucher.Id }}
              b(v-else-if="voucherIds && Array.isArray(voucherIds) && voucherIds.length") {{ $t("constants.additionalVouchers") }}:&nbsp;{{ voucherIds }}
        tbody
          tr(style="line-height: 20px;")
            td
              b {{ $t("constants.travelPeriod") }}
            td
              b {{ $t("constants.status") }}
            td
          tr(v-for="bookingDateRequest in bookingDateRequests")
            td {{ bookingDateRequest.Date | formatDate }}  {{ $t("constants.until") }}  {{ moment(bookingDateRequest.Date).add(product.Uebernachtungen, "days").toDate() | formatDate }}
            td(style="font-weight:700; color: #1b3f68")
              span(v-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRequested") {{ $t("booking-requested.status") }}
              span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanBeBooked") {{ $t("booking-can-be-booked.status") }}
              span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateBooked") {{ $t("booking-confirmed.status") }}
              span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateRejected") {{ $t("booking-rejected.status") }}
              span(v-else-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanceled") {{ $t("booking-canceled.status") }}
            td
              button.button.button-primary.button-tbook(
                v-if="bookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanBeBooked"
                @click="saveBookingDateRequest(bookingDateRequest.Id)"
                :disabled="isDisabled"
                :class="{ 'disabled': isDisabled }"
              ) {{ $t("booking-can-be-booked.button") }}

  .col-12.mt-3
    p(v-if="bookingRequest.Title == 'Herr'") {{ $t("constants.AddressM") }} {{ bookingRequest.LastName }},
    p(v-else) {{ $t("constants.AddressF") }} {{ bookingRequest.LastName }},

    p(v-if="parentVoucher") {{ $t("constants.mainVoucherInfo").toString().replace("voucherId", parentVoucher.Id) }}
    template(v-else)
      p {{ $t("booking-can-be-booked.text1") }}
      p {{ $t("booking-can-be-booked.text2") }}
    p.mb-0 {{ $t("constants.closing") }}
    img(:src='$t("constants.touriBookTeam", {publicPath: publicPath})', style="height: 30px")

</template>

<script>
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
const moment = require("moment");

export default {
  name: "TouribookCanBeBooked",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
    saveBookingRequest: {
      type: Function,
      required: true,
    },
    vouchers: {
      type: Array,
      required: true,
    },
    voucherIds: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,

      BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
    };
  },
  methods: {
    async saveBookingDateRequest(bookingDateRequestId) {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie die das Datum verbindlich buchen möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        this.bookingDateRequests
          .filter((b) => b.Id == bookingDateRequestId)
          .forEach(
            (b) =>
              (b.BookingDateRequestStatusId =
                BookingDateRequestStatusEnum.DateBooked)
          );
        const savedBookingRequest = await this.saveBookingRequest(
          this.bookingRequest,
          this.vouchers,
          this.bookingTicket,
          this.bookingDateRequests
        );
        if (savedBookingRequest.Message) {
          this.$alert(savedBookingRequest.Message);
        }
        if (savedBookingRequest.IsSuccess) {
          this.loadVoucher();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
