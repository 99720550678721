<template lang="pug">
.row
  .col-12.text-center
    .background-circle
      .fa-4x(style="color:#fff").align-self-center
        font-awesome-icon(:icon="['fas', 'calendar-alt']")
    h2.mt-3 {{ $t("customer-data.headline1") }}
    h4 {{ $t("customer-data.headline2") }} {{ voucher.Id }}
    h5.font-weight-bold.my-5(v-if="parentVoucher") {{ $t("constants.mainVoucherInfo").toString().replace("voucherId", parentVoucher.Id) }}

  .col-12.mt-3
    h5 {{ $t("customer-data.section-headline1") }}
    p {{ $t("customer-data.section-text") }}

  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.formOfAddress") }}
      select.form-control(v-model="bookingRequest.Title" :disabled="isDisabled")
        option(value="Herr") {{ $t("customer-data.gender.m") }}
        option(value="Frau") {{ $t("customer-data.gender.f") }}
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.firstName") }}
      input.form-control(:placeholder="this.$t('customer-data.firstNamePlaceholder')" v-model="bookingRequest.FirstName" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.lastName") }}
      input.form-control(:placeholder="this.$t('customer-data.lastNamePlaceholder')" v-model="bookingRequest.LastName" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.streetAndNumber") }}
      input.form-control(:placeholder="this.$t('customer-data.streetAndNumberPlaceholder')" v-model="bookingRequest.Street" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.zipCode") }}
      input.form-control(:placeholder="this.$t('customer-data.zipCodePlaceholder')" v-model="bookingRequest.ZipCode" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.city") }}
      input.form-control(:placeholder="this.$t('customer-data.cityPlaceholder')" v-model="bookingRequest.City" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.country") }}
      select.form-control(v-model="bookingRequest.Country" :disabled="isDisabled")
        option(:value="addressCountry.ISO31661alpha3" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}

  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.guestMail") }}
      input.form-control(:placeholder="this.$t('customer-data.guestMailPlaceholder')" v-model="bookingRequest.Email" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.repeat-guestMail") }}
      input.form-control(:placeholder="this.$t('customer-data.repeat-guestMailPlaceholder')" v-model="confirmationEmail" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.phone") }}
      input.form-control(:placeholder="this.$t('customer-data.phonePlaceholder')" v-model="bookingRequest.Phone" :disabled="isDisabled")
  .col-12.col-md-4
    .form-group
      label.form-label {{ $t("customer-data.dateOfBirth") }}
      input.form-control(type="date" :placeholder="this.$t('customer-data.datePlaceholder')" :value="bookingRequest.BirthDate | formatInputDate" @change="bookingRequest.BirthDate = $event.target.value;" :disabled="isDisabled")

  .col-12.mt-3
    h5 {{ $t("customer-data.section-headline4") }}
    p {{ $t("customer-data.groupBookingVouchers") }}
  .col-md-4.col-sm-12
    label.form-label Gutschein
    input.form-control(v-model="voucherId" :disabled="isDisabled")
  .col-md-4.col-sm-12
    label.form-label Code
    input.form-control(v-model="voucherCode" v-on:keyup.enter="addVoucher" :disabled="isDisabled")
  .col-md-4.col-sm-12.mt-sm-3.d-lg-flex.align-items-end
    button.button.button-primary.button-tbook.w-100(@click="addVoucher" :disabled="isDisabled" :class="{ 'disabled': isDisabled }") Gutschein hinzufügen

  .col-12.mt-3(v-if="currentVouchers && Array.isArray(currentVouchers) && currentVouchers.length")
    voucher-cards.mt-3(
      :vouchers="currentVouchers"
      :remove-voucher="removeVoucher"
      :is-disabled="isDisabled"
    )

  .col-12.mt-3
    h5 {{ $t("customer-data.section-headline2") }}
  .col-12.col-lg-6.mt-3
    img.img-fluid(:src="productImage" v-if="productImage")
    img.img-fluid(v-else :src="`${publicPath}images/bg-image.jpg`" alt="")
  .col-12.col-lg-6.mt-3
    h5 {{ $t("customer-data.arrangement") }}
    p {{ voucher.Arrangement }}

    h5 {{ $t("customer-data.services") }}
    ul
      li(v-for="holidayService in holidayServices" v-if="holidayService.trim()") {{ holidayService.trim() }}

    template(v-if="additionalHolidayServices && Array.isArray(additionalHolidayServices) && additionalHolidayServices.length")
      h5(style="font-style: italic;") {{ $t("customer-data.additionalServices") }}
      ul
        li(v-for="additionalHolidayService in additionalHolidayServices" v-if="additionalHolidayService.trim()") {{ additionalHolidayService.trim() }}

    template(v-if="host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook")
      h5 {{ $t("customer-data.bookability") }}
      p {{ voucher.BookingAvailability }}

  .col-12.mt-2
    h5 {{ $t("customer-data.section-headline3") }}
    p(v-if="host && host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook") {{$t("customer-data.section-text3")}}

  .col-12.col-lg-6.mt-2
    booking-date-picker(
      :lang="lang"
      :disabled-spans="disabledSpans"
      :change-date="changeDate"
      :select-date="selectDate"
      :disabled="isDisabled"
    )

  .col-12.col-lg-6.mt-sm-3
    h5 {{ $t("customer-data.desiredDate") }}
    .row.mt-3(v-if="!host.ChannelPropertyTypeId || host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook")
      .col-12(v-for="bookingDate in bookingDates" :class="{ 'mt-3': bookingDates.indexOf(bookingDate) > 0 }")
        .input-group
          .input-group-prepend
            button.button.button-primary.button-tdays(@click="selectedDatePicker = bookingDates.indexOf(bookingDate)" :disabled="isDisabled" :class="{ 'disabled': isDisabled }")
              font-awesome-icon(icon="fa-solid fa-check")
          input.form-control(type="date" :placeholder="$t('customer-data.datePlaceholder')" :value="bookingDate | formatInputDate" disabled)
          .input-group-append(v-if="bookingDates.length > 1")
            button.button.button-primary.button-tpics(@click="bookingDates.splice(bookingDates.indexOf(bookingDate), 1)" :disabled="isDisabled" :class="{ 'disabled': isDisabled }")
              font-awesome-icon(:icon="['fas','trash']")

      .col-12
        button.button.button-secondary.button-tbook.w-100.mt-4(@click="addDate" :disabled="isDisabled" :class="{ 'disabled': isDisabled }") {{ $t("buttons.alternativeDate") }}
        p.text-muted.mt-2 {{ $t("customer-data.selectDatesInfo") }}
        .form-check.text-muted.mt-5
          input.form-check-input(type="checkbox" v-model="isBindingBooking" id="isBindingBooking")
          label.form-check-label(for="isBindingBooking") {{ $t("customer-data.checkToBook") }}

    .row(v-else)
      .col-12
        input.form-control(type="date" :placeholder="$t('customer-data.datePlaceholder')" :value="bookingDates[0] | formatInputDate" disabled)

  .col-12.text-right.mb-3.mt-sm-3
    button.button.button-primary.button-tbook(@click="save" :disabled="isDisabled" :class="{ 'disabled': isDisabled }") {{ $t("buttons.sendDate") }}

</template>

<script>
import EventBus from "@/event-bus";
import { de, en } from "vuejs-datepicker/dist/locale";
import { HostImages } from "@/graphql/hostImages/HostImages.ts";
import { AddressCountries } from "@/graphql/addressCountries/AddressCountries.ts";
import moment from "moment";
import ExclusionPeriodsComponent from "@/components/exclusionPeriods/ExclusionPeriodsComponent";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import { EmailValidatorComponent } from "@/components/emailValidator/EmailValidatorComponent.ts";
import BookingDatePicker from "../../components/datepicker/BookingDatePicker.vue";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import VoucherStatusEnum from "@/utils/enums/voucherStatus/VoucherStatusEnum";
import VoucherCards from "@/views/partials/vouchers/VoucherCards.vue";
import VoucherGroupBookingsComponent from "@/components/voucherGroupBookings/VoucherGroupBookingsComponent";

export default {
  name: "TouribookCustomerData",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    vouchers: {
      type: Array,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
    saveBookingRequest: {
      type: Function,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    VoucherCards,
    BookingDatePicker,
  },
  data() {
    return {
      confirmationEmail: "",
      bookingDates: [undefined],
      selectedDatePicker: 0,
      hostImages: [],
      isBindingBooking: false,
      publicPath: process.env.BASE_URL,
      lang: localStorage.localeSettings == "en" ? en : de,
      disabledSpans: {
        ranges: [
          {
            from: moment().subtract(100, "years").toDate(),
            to: moment().add(100, "years").toDate(),
          },
        ],
        days: [],
        dates: [],
      },
      currentDate: moment().startOf("month").toDate(),
      addressCountries: [],

      currentVouchers: this.vouchers,
      voucherId: "",
      voucherCode: "",

      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  async mounted() {
    try {
      EventBus.$on(
        "changeLanguage",
        function (language) {
          this.lang = language == "en" ? en : de;
        }.bind(this)
      );
      this.loadAddressCountries();
      this.loadHostImages();
      this.loadExclusionPeriods();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    async addVoucher() {
      try {
        if (this.voucherId == this.voucher?.Id) {
          return this.$alert(
            `Sie haben sich bereits mit dem Gutschein ${this.voucherId} eingeloggt.`
          );
        }
        if (this.currentVouchers.find((v) => v.Id == this.voucherId)) {
          return this.$alert(
            `Der Gutschein ${this.voucherId} ist bereits teil der Gruppenanfrage.`
          );
        }
        const vouchersComponent = new VouchersComponent();
        EventBus.$emit("changeLoadingState", true);
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.voucherId
        );
        if (!voucher) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert(
            `Es konnte kein Gutschein für ${this.voucherId} gefunden werden.`
          );
        }
        if (
          voucher.Guid.slice(-6).toLowerCase() != this.voucherCode.toLowerCase()
        ) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert(
            `Der Code stimmt nicht mit dem des Gutscheins überein.`
          );
        }
        if (voucher.Status != VoucherStatusEnum.Sent) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert(
            "Der Gutschein hat nicht den Status 'versendet' und ist somit nicht geeignet."
          );
        }
        if (voucher.ProductId != this.voucher.ProductId) {
          EventBus.$emit("changeLoadingState", false);
          return this.$alert(
            "Die Produkte der Gutscheine stimmen nicht überein."
          );
        }
        EventBus.$emit("changeLoadingState", false);
        this.currentVouchers.push(voucher);
        this.voucherId = "";
        this.voucherCode = "";
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async removeVoucher(voucher) {
      try {
        if (!voucher) {
          return;
        }
        const voucherGroupBookingsComponent =
          new VoucherGroupBookingsComponent();
        EventBus.$emit("changeLoadingState", true);
        const voucherGroupBooking =
          await voucherGroupBookingsComponent.getVoucherGroupBooking(
            this.$apollo,
            this.bookingRequest.Id,
            voucher.Id
          );
        EventBus.$emit("changeLoadingState", false);
        if (voucherGroupBooking) {
          const confirmed = await this.$confirm(
            "Sind Sie sicher, dass Sie die Gutscheingruppenanfrage löschen möchten?"
          )
            .then()
            .catch((e) => e);
          if (!confirmed) {
            return;
          }
          EventBus.$emit("changeLoadingState", true);
          const deletedVoucherGroupBooking =
            await voucherGroupBookingsComponent.deleteVoucherGroupBooking(
              this.$apollo,
              this.bookingRequest.Id,
              voucher.Id
            );
          EventBus.$emit("changeLoadingState", false);
          if (!deletedVoucherGroupBooking) {
            return this.$alert(
              "Die Gutscheingruppenanfrage konnte nicht gelöscht werden."
            );
          }
          if (!deletedVoucherGroupBooking.IsSuccess) {
            return this.$alert(deletedVoucherGroupBooking.Message);
          }
        }
        this.currentVouchers.splice(this.currentVouchers.indexOf(voucher), 1);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHostImages() {
      try {
        const hostImages = await this.$apollo
          .query({
            query: HostImages.Queries.HostImagesByProductId,
            variables: {
              productId: this.voucher.ProductId,
            },
          })
          .then(({ data }) => data?.hostImagesByProductId)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!hostImages) {
          return;
        }
        this.hostImages = hostImages;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadExclusionPeriods() {
      try {
        if (this.parentVoucher) {
          return;
        }
        EventBus.$emit("changeCalendarLoading", true);
        const exclusionPeriodsComponent = new ExclusionPeriodsComponent();
        const exclusionPeriods =
          await exclusionPeriodsComponent.getExclusionPeriods(
            this.$apollo,
            this.voucher.Id,
            this.from,
            this.to
          );
        EventBus.$emit("changeCalendarLoading", false);
        if (!exclusionPeriods) {
          return;
        }
        const exclusionDays = [];
        for (const exclusionDay of exclusionPeriods.ExclusionDays) {
          exclusionDays.push(new Date(exclusionDay));
        }
        if (exclusionPeriods.ExclusionSpans) {
          exclusionPeriods.ExclusionSpans.forEach((e) => {
            e.from = new Date(e.from);
            e.to = new Date(e.to);
          });
        }
        this.disabledSpans = {
          ranges: exclusionPeriods.ExclusionSpans,
          days: exclusionPeriods.ExclusionWeekDays,
          dates: exclusionDays,
        };
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddressCountries() {
      try {
        const addressCountries = await this.$apollo
          .query({
            query: AddressCountries.Queries.AddressCountries,
          })
          .then(({ data }) => data?.addressCountries)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!addressCountries) {
          return;
        }
        this.addressCountries = addressCountries;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async save() {
      try {
        const validatedBookingRequest = this.validateBookingRequest(
          this.bookingRequest,
          this.bookingDates,
          this.host
        );
        if (!validatedBookingRequest) {
          return this.$alert(
            "Die Buchungsanfrage konnte nicht geprüft werden."
          );
        }
        if (!validatedBookingRequest.IsSuccess) {
          return this.$alert(validatedBookingRequest.Message);
        }

        let result = await this._saveBookingRequest();
        if (!result) {
          return this.$alert(
            "Die Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es später erneut."
          );
        }
        if (result.Message) {
          this.$alert(result.Message);
        }
        if (result.IsSuccess) {
          this.loadVoucher();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _saveBookingRequest() {
      try {
        let confirmationString = "customer-data.alerts.sendRequests";
        if (this.isBindingBooking) {
          confirmationString = "customer-data.alerts.confirmSaveRequests";
        }
        const confirmed = await this.$confirm(
          this.$t(confirmationString).toString()
        )
          .then()
          .catch();
        if (!confirmed) {
          return;
        }
        const bookingTicket = {
          BookingTicketStatusId: BookingTicketStatusEnum.DatesRequested,
          IsBindingBooking: this.isBindingBooking,
        };
        const bookingDateRequests = [];
        this.bookingDates.forEach((date) =>
          bookingDateRequests.push({
            Date: date,
            BookingDateRequestStatusId:
              BookingDateRequestStatusEnum.DateRequested,
          })
        );
        const savedBookingRequest = await this.saveBookingRequest(
          this.bookingRequest,
          this.currentVouchers,
          bookingTicket,
          bookingDateRequests
        );
        if (!savedBookingRequest) {
          return {
            IsSuccess: false,
            Message:
              "Ihre Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
          };
        }
        return savedBookingRequest;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    validateBookingRequest(bookingRequest, bookingDates) {
      try {
        if (!bookingRequest.Title) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.formOfAddress").toString(),
          };
        }
        if (!bookingRequest.FirstName) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.firstName").toString(),
          };
        }
        if (!bookingRequest.LastName) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.lastName").toString(),
          };
        }
        if (!bookingRequest.Street) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.streetAndNumber").toString(),
          };
        }
        if (!bookingRequest.ZipCode) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.zipCode").toString(),
          };
        }
        if (!bookingRequest.City) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.city").toString(),
          };
        }
        if (!bookingRequest.Country) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.country").toString(),
          };
        }
        if (!bookingRequest.Email) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.email").toString(),
          };
        }
        if (!this.confirmationEmail) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.repeatEmail").toString(),
          };
        }
        if (
          bookingRequest.Email.toLowerCase() !==
          this.confirmationEmail.toLowerCase()
        ) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.mailsDontMatch").toString(),
          };
        }
        const emailValidatorComponent = new EmailValidatorComponent();
        const isValidEmail = emailValidatorComponent.validateEmail(
          bookingRequest.Email
        );
        if (!isValidEmail) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.mailsNotValid").toString(),
          };
        }
        if (bookingRequest.BirthDate) {
          bookingRequest.BirthDate = moment(bookingRequest.BirthDate).toDate();
          if (!this.isValidDate(bookingRequest.BirthDate, true)) {
            return {
              IsSuccess: false,
              Message: this.$t("customer-data.alerts.birthDate").toString(),
            };
          }
        } else {
          bookingRequest.BirthDate = null;
        }
        if (bookingDates.some((b) => !b)) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.dataNotFilled").toString(),
          };
        }
        if (bookingDates.some((b) => !this.isValidDate(b))) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.dataNotCorrect").toString(),
          };
        }
        return {
          IsSuccess: true,
        };
      } catch (e) {
        console.error(e);
        return {
          IsSuccess: false,
          Message: e.message,
        };
      }
    },
    selectDate(date) {
      try {
        if (this.bookingDates.find((d) => d && moment(d).isSame(date, "day"))) {
          return this.$alert(
            "Das Datum ist schon unter Ihren Wunschterminen, bitte wählen Sie ein anderes Datum aus."
          );
        }
        this.bookingDates[this.selectedDatePicker] = date;
        const _bookingDates = JSON.stringify(this.bookingDates);
        this.bookingDates = undefined;
        this.bookingDates = JSON.parse(_bookingDates);
        this.currentDate = date;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    addDate() {
      try {
        if (this.bookingDates.length == 3) {
          return this.$alert(
            this.$t("customer-data.alerts.maxRequests").toString()
          );
        }
        this.bookingDates.push(undefined);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    isValidDate(date, checkFutureDates) {
      try {
        if (!date) {
          return false;
        }
        if (date.toString() == "Invalid Date") {
          return false;
        }
        if (date instanceof Date && isNaN(date)) {
          return false;
        }
        let isValid = moment(date).isValid();
        if (!isValid) {
          return false;
        }
        const _date = new Date(date);
        if (Object.prototype.toString.call(_date) === "[object Date]") {
          if (isNaN(_date.getTime())) {
            return false;
          }
        } else {
          return false;
        }
        if (moment(date).year() < 1900) {
          return false;
        }
        if (moment(date).year() > moment().add(5, "years").year()) {
          return false;
        }
        if (!checkFutureDates) {
          return true;
        }
        if (moment(date).year() > moment().year()) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    changeDate(date) {
      try {
        if (date.year) {
          return;
        } else if (date.month) {
          this.currentDate = moment().month(date.month).toDate();
        } else if (moment(date).isValid()) {
          this.currentDate = date;
        }
        if (
          !this.host.ChannelPropertyTypeId ||
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook
        ) {
          return;
        }
        this.loadExclusionPeriods();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    vouchers() {
      try {
        this.currentVouchers = this.vouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    holidayServices() {
      try {
        if (!this.voucher) {
          return [];
        }
        if (!this.voucher.HolidayServices) {
          return [];
        }
        const holidayServices = this.voucher.HolidayServices;
        if (!holidayServices) {
          return [];
        }
        return this.voucher.HolidayServices.split("\n");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    additionalHolidayServices() {
      try {
        if (!this.voucher) {
          return [];
        }
        if (!this.voucher.AdditionalHolidayServices) {
          return [];
        }
        const holidayServices = this.voucher.AdditionalHolidayServices;
        if (!holidayServices) {
          return [];
        }
        return this.voucher.AdditionalHolidayServices.split("\n");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    productImage() {
      try {
        try {
          const articleImage = this.hostImages.find(
            (i) =>
              i.Image.ImageTypeId == 6 ||
              i.Image.ImageName.toLowerCase().includes("artikelbild")
          );
          if (!articleImage) {
            return undefined;
          }
          return articleImage.RemoteBaseUrl + "large.jpg";
        } catch (e) {
          console.error(e);
          this.$alert(e.message);
          return undefined;
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    from() {
      try {
        return moment(this.currentDate).startOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    to() {
      try {
        return moment(this.currentDate).endOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
