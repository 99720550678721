<template lang="pug">
.container-fluid
  .row
    .col-12.mt-2
      .float-left
        button.button.button-primary.button-tpics(@click="openReportErrorModal" :disabled="!voucher" :class="{ 'disabled': !voucher }") {{ $t("report-error-modal.headline") }}
      .float-right
        button.button.button-primary.button-tbook(@click="loadVoucher();")
          font-awesome-icon(icon="fa-solid fa-rotate-right")

    .col-12(v-if="currentVoucher && product && host")
      touribook-home(
        v-if="!host.ChannelPropertyTypeId || host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook"
        :voucher="voucher"
        :booking-request="bookingRequest"
        :booking-ticket="bookingTicket"
        :product="product"
        :host="host"
        :parent-voucher="parentVoucher"
        :vouchers="vouchers"
        :load-voucher="loadVoucher"
        :load-vouchers="loadVouchers"
      )
      hyperguest-home(
        v-else-if="host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.HyperGuest"
        :voucher="voucher"
        :booking-request="bookingRequest"
        :booking-ticket="bookingTicket"
        :product="product"
        :host="host"
        :load-voucher="loadVoucher"
      )

</template>

<script>
import EventBus from "@/event-bus";
import ProductsComponent from "@/components/products/ProductsComponent";
import HostsComponent from "@/components/hosts/HostsComponent";
import ReportError from "@/views/modals/report-error.vue";
import BookingDateRequestsComponent from "@/components/bookingDateRequests/BookingDateRequestsComponent";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import BookingRequestsComponent from "@/components/bookingRequests/BookingRequestsComponent";
import { BookingTicketsComponent } from "@/components/bookingTickets/BookingTicketsComponent";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import HyperguestHome from "@/views/hyperGuest/HyperguestHome.vue";
import TouribookHome from "@/views/touriBook/TouribookHome.vue";
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";

export default {
  name: "Home",
  components: { TouribookHome, HyperguestHome },
  data() {
    return {
      voucherId: this.$session.get("voucherId"),
      voucher: undefined,
      bookingRequest: {
        Title: "Herr",
        Country: "DEU",
        VoucherId: this.$session.get("voucherId"),
      },
      bookingTickets: [],
      bookingDateRequests: [],
      product: undefined,
      host: undefined,
      parentVoucher: undefined,
      vouchers: [],

      BookingTicketStatusEnum: BookingTicketStatusEnum,
      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  mounted() {
    try {
      this.loadVoucher();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVoucher() {
      try {
        this.voucher = undefined;
        const vouchersComponent = new VouchersComponent();
        EventBus.$emit("changeLoadingState", true);
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.voucherId
        );
        EventBus.$emit("changeLoadingState", false);
        if (!voucher) {
          return;
        }
        this.voucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingRequest() {
      try {
        const bookingRequestsComponent = new BookingRequestsComponent();
        const bookingRequest =
          await bookingRequestsComponent.getBookingRequestByVoucherId(
            this.$apollo,
            this.currentVoucher?.Id
          );
        if (!bookingRequest) {
          return;
        }
        this.bookingRequest = bookingRequest;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingTickets() {
      try {
        const bookingTicketsComponent = new BookingTicketsComponent();
        const bookingTickets =
          await bookingTicketsComponent.getBookingTicketsByBookingRequestId(
            this.$apollo,
            this.bookingRequest?.Id
          );
        if (!bookingTickets) {
          return;
        }
        this.bookingTickets = bookingTickets;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.currentVoucher?.ProductId
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHost() {
      try {
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(
          this.$apollo,
          this.product.HostId
        );
        if (!host) {
          return;
        }
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingDateRequests() {
      try {
        const bookingDateRequestsComponent = new BookingDateRequestsComponent();
        const bookingDateRequests =
          await bookingDateRequestsComponent.getBookingDateRequests(
            this.$apollo,
            this.bookingTicket?.Id
          );
        if (!bookingDateRequests) {
          return;
        }
        this.bookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadVouchers() {
      try {
        const vouchersComponent = new VouchersComponent();
        const vouchers = await vouchersComponent.getVouchers(
          this.$apollo,
          this.bookingRequest?.Id
        );
        if (!vouchers) {
          return;
        }
        this.vouchers = vouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    openReportErrorModal() {
      try {
        this.$modal.show(
          ReportError,
          {
            voucherId: this.voucher.Id,
          },
          {
            scrollable: true,
            resizable: true,
            height: "auto",
            width: "80%",
          }
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadParentVoucher() {
      try {
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          undefined,
          this.voucher?.Id
        );
        EventBus.$emit("changeLoadingState", false);
        if (!voucher || voucher.Id == this.voucher.Id) {
          return;
        }
        this.parentVoucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    currentVoucher() {
      try {
        if (this.parentVoucher) {
          return this.parentVoucher;
        }
        return this.voucher;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    bookingTicket() {
      try {
        if (!this.bookingTickets) {
          return undefined;
        }
        if (this.bookingTickets.length == 0) {
          return undefined;
        }
        const bookingTicket = this.bookingTickets.find(
          (b) =>
            b.BookingTicketStatusId != BookingTicketStatusEnum.DatesRejected &&
            b.BookingTicketStatusId != BookingTicketStatusEnum.DatesCancelled
        );
        if (!bookingTicket) {
          return undefined;
        }
        return bookingTicket;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    voucherIds() {
      try {
        if (
          !this.vouchers ||
          !Array.isArray(this.vouchers) ||
          !this.vouchers.length
        ) {
          return undefined;
        }
        const voucherIds = [];
        this.vouchers.forEach((v) => voucherIds.push(v.Id));
        return voucherIds.join(", ");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    isDisabled() {
      try {
        if (!this.voucher || !this.parentVoucher) {
          return false;
        }
        if (this.parentVoucher) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
  },
  watch: {
    voucher() {
      try {
        if (!this.voucher?.Id) {
          return;
        }
        this.loadParentVoucher();
        this.loadBookingRequest();
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    parentVoucher() {
      try {
        if (!this.parentVoucher?.Id) {
          return;
        }
        this.loadBookingRequest();
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingRequest() {
      try {
        if (!this.bookingRequest?.Id) {
          return;
        }
        this.loadBookingTickets();
        this.loadVouchers();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    product() {
      try {
        if (!this.product?.Id) {
          return;
        }
        this.loadHost();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingTicket() {
      try {
        if (!this.bookingTicket?.Id) {
          return;
        }
        this.loadBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
