<template lang="pug">
.voucher-group-booking-cards
  h5 Zusätzliche Gutscheine:
  voucher-card(
    v-for="voucher in vouchers"
    :voucher="voucher"
    :remove-voucher="removeVoucher"
    :is-disabled="isDisabled"
    :key="voucher.Id"
  )

</template>

<script>
import VoucherCard from "@/views/partials/vouchers/VoucherCard.vue";

export default {
  name: "VoucherCards",
  components: { VoucherCard },
  props: {
    vouchers: {
      type: Array,
      required: true,
    },
    removeVoucher: {
      type: Function,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped></style>
