import gql from "graphql-tag";

export const VoucherGroupBookings = {
    Queries: {
        VoucherGroupBooking: gql`
        query voucherGroupBooking($bookingRequestId: ID, $voucherId: ID) {
            voucherGroupBooking(bookingRequestId: $bookingRequestId, voucherId: $voucherId) {
                Id
                BookingRequestId
                VoucherId
                CreatedAt
            }
        }`,
    },
    Mutations: {
        SaveVoucherGroupBooking: gql`
        mutation saveVoucherGroupBooking($voucherGroupBooking: VoucherGroupBookingsInput) {
            saveVoucherGroupBooking(voucherGroupBooking: $voucherGroupBookings) {
                IsSuccess
                Message
            }
        }`,
        DeleteVoucherGroupBooking: gql`
        mutation deleteVoucherGroupBooking($bookingRequestId: ID, $voucherId: ID) {
            deleteVoucherGroupBooking(bookingRequestId: $bookingRequestId, voucherId: $voucherId) {
                IsSuccess
                Message
            }
        }`,
    },
};
