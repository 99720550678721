<template lang="pug">
.card
  .card-body
    .row
      .col.d-flex.align-self-center
        h5.font-weight-bold.mb-0 Gutschein:
      .col.d-flex.align-self-center
        h5.font-weight-bold.mb-0 {{ voucher.Id }}
      .col(v-if="removeVoucher")
        .float-right
          button.button.button-primary.button-tpics(
            @click="removeVoucher(voucher)"
            :disabled="isDisabled"
            :class="{ 'disabled': isDisabled }"
          ) entfernen

</template>

<script>
export default {
  name: "VoucherCard",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    removeVoucher: {
      type: Function,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped></style>
