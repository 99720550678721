<template lang="pug">
.hyperguest-guests
  hyperguest-guest(
    v-if="guests && Array.isArray(guests) && guests.length"
    v-for="guest in guests"
    :guest="guest"
    :index="guests.indexOf(guest)"
    :address-countries="addressCountries"
    :key="guests.indexOf(guest)"
    :class="{ 'mt-4': guests.indexOf(guest) > 0 }"
  )
</template>

<script>
import HyperguestGuest from "@/views/hyperGuest/partials/HyperguestGuest.vue";

export default {
  name: "HyperguestGuests",
  components: { HyperguestGuest },
  props: {
    guests: {
      type: Array,
      required: true,
    },
    addressCountries: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
