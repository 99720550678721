const {
  BookingDateRequests,
} = require("@/graphql/bookingDateRequests/BookingDateRequests.ts");

class BookingDateRequestsComponent {
  async getBookingDateRequests(apollo, bookingTicketId) {
    try {
      if (!apollo || !bookingTicketId) {
        return undefined;
      }
      const bookingDateRequests = await apollo
        .query({
          query:
            BookingDateRequests.Queries.BookingDateRequestsByBookingTicketId,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicketId,
          },
        })
        .then(({ data }) => data?.bookingDateRequestsByBookingTicketId)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!bookingDateRequests) {
        return undefined;
      }
      return bookingDateRequests;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = BookingDateRequestsComponent;
