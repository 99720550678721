const { Vouchers } = require("@/graphql/vouchers/Vouchers.ts");

class VouchersComponent {
  async loginVoucher(apollo, voucherId, code) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!voucherId) {
        return {
          IsSuccess: false,
          Message: "Keine Gutschein-Id gefunden.",
        };
      }
      if (!code) {
        return {
          IsSuccess: false,
          Message: "Kein Code gefunden.",
        };
      }
      const login = await apollo
        .mutate({
          mutation: Vouchers.Mutations.LoginVoucher,
          fetchPolicy: "no-cache",
          variables: {
            id: voucherId,
            code: code,
          },
        })
        .then(({ data }) => data?.LoginVoucher)
        .catch((e) => {
          console.error(e);
          return {
            IsSuccess: false,
            Message: e.message,
          };
        });
      return login;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }

  async getVoucher(apollo, id, voucherId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!id && !voucherId) {
        return undefined;
      }
      const voucher = await apollo
        .query({
          query: Vouchers.Queries.Voucher,
          fetchPolicy: "no-cache",
          variables: {
            id: id,
            voucherId: voucherId,
          },
        })
        .then(({ data }) => data?.voucher)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!voucher) {
        return undefined;
      }
      return voucher;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getVouchers(apollo, bookingRequestId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!bookingRequestId) {
        return undefined;
      }
      const vouchers = await apollo
        .query({
          query: Vouchers.Queries.Vouchers,
          fetchPolicy: "no-cache",
          variables: {
            bookingRequestId: bookingRequestId,
          },
        })
        .then(({ data }) => data?.vouchers)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!vouchers) {
        return undefined;
      }
      return vouchers;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getVoucherByVoucherAuthentificationUrl(
    apollo,
    voucherAuthentificationUrl
  ) {
    try {
      if (!apollo || !voucherAuthentificationUrl) {
        return undefined;
      }
      const voucher = await apollo
        .query({
          query: Vouchers.Queries.VoucherByVoucherAuthentificationUrl,
          fetchPolicy: "no-cache",
          variables: {
            voucherAuthentificationUrl: voucherAuthentificationUrl,
          },
        })
        .then(({ data }) => data?.voucherByVoucherAuthentificationUrl)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return voucher;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = VouchersComponent;
