<template lang="pug">
.container-fluid
  .row
    .col-12(v-if="currentVoucher && product && host")
      .content-wrapper
        touribook-customer-data.mt-5(
          v-if="!bookingTicket || bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRejected || bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelled"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :host="host"
          :parentVoucher="parentVoucher"
          :vouchers="vouchers"
          :is-disabled="isDisabled"
          :save-booking-request="saveBookingRequest"
          :load-voucher="loadVoucher"
        )
        touribook-booking-requested(
          v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :booking-ticket="bookingTicket"
          :booking-date-requests="bookingDateRequests"
          :product="product"
          :parent-voucher="parentVoucher"
          :voucher-ids="voucherIds"
        )
        touribook-can-be-booked(
          v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCanBeBooked"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :booking-ticket="bookingTicket"
          :booking-date-requests="bookingDateRequests"
          :product="product"
          :parent-voucher="parentVoucher"
          :vouchers="vouchers"
          :voucher-ids="voucherIds"
          :is-disabled="isDisabled"
          :load-voucher="loadVoucher"
          :save-booking-request="saveBookingRequest"
        )
        touribook-booking-confirmed(
          v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :booking-ticket="bookingTicket"
          :booking-date-requests="bookingDateRequests"
          :product="product"
          :host="host"
          :parent-voucher="parentVoucher"
          :vouchers="vouchers"
          :is-disabled="isDisabled"
          :load-voucher="loadVoucher"
          :save-booking-request="saveBookingRequest"
          :load-vouchers="loadVouchers"
        )
        touribook-cancel-requested(
          v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelRequested"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :booking-ticket="bookingTicket"
          :booking-date-requests="bookingDateRequests"
          :product="product"
          :parent-voucher="parentVoucher"
          :voucher-ids="voucherIds"
        )

</template>

<script>
import EventBus from "@/event-bus";
import BookingDateRequestsComponent from "@/components/bookingDateRequests/BookingDateRequestsComponent";
import BookingRequestsComponent from "@/components/bookingRequests/BookingRequestsComponent";
import TouribookCustomerData from "@/views/touriBook/partials/TouribookCustomerData.vue";
import TouribookBookingRequested from "@/views/touriBook/partials/TouribookBookingRequested.vue";
import TouribookCanBeBooked from "@/views/touriBook/partials/TouribookCanBeBooked.vue";
import TouribookBookingConfirmed from "@/views/touriBook/partials/TouribookBookingConfirmed.vue";
import TouribookCancelRequested from "@/views/touriBook/partials/TouribookCancelRequested.vue";
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";

export default {
  name: "TouribookHome",
  components: {
    TouribookCancelRequested,
    TouribookBookingConfirmed,
    TouribookCanBeBooked,
    TouribookBookingRequested,
    TouribookCustomerData,
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    vouchers: {
      type: Array,
      required: false,
    },
    // FUNCTIONS
    loadVoucher: {
      type: Function,
      required: true,
    },
    loadVouchers: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      bookingDateRequests: [],

      BookingTicketStatusEnum: BookingTicketStatusEnum,
    };
  },
  methods: {
    async loadBookingDateRequests() {
      try {
        if (!this.bookingTicket?.Id) {
          return;
        }
        const bookingDateRequestsComponent = new BookingDateRequestsComponent();
        const bookingDateRequests =
          await bookingDateRequestsComponent.getBookingDateRequests(
            this.$apollo,
            this.bookingTicket?.Id
          );
        if (!bookingDateRequests) {
          return;
        }
        this.bookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveBookingRequest(
      bookingRequest,
      vouchers = undefined,
      bookingTicket = undefined,
      bookingDateRequests = undefined
    ) {
      try {
        if (!bookingRequest) {
          return {
            IsSuccess: false,
            Message: "Keine Buchungsanfrage gefunden.",
          };
        }
        const bookingRequestsComponent = new BookingRequestsComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedBookingRequest =
          await bookingRequestsComponent.saveBookingRequest(
            this.$apollo,
            bookingRequest,
            vouchers,
            bookingTicket,
            bookingDateRequests
          );
        EventBus.$emit("changeLoadingState", false);
        if (!savedBookingRequest) {
          return {
            IsSuccess: false,
            Message:
              "Ihre Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
          };
        }
        return savedBookingRequest;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    currentVoucher() {
      try {
        if (this.parentVoucher) {
          return this.parentVoucher;
        }
        return this.voucher;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    voucherIds() {
      try {
        const voucherIds = [];
        this.vouchers.forEach((v) => voucherIds.push(v.Id));
        return voucherIds.join(", ");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    isDisabled() {
      try {
        if (!this.voucher || !this.parentVoucher) {
          return false;
        }
        if (this.parentVoucher) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
  },
  watch: {
    bookingTicket() {
      try {
        this.loadBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
