<template lang="pug">
.hyperguest-booking-data
  .row
    .col-12.text-center
      .background-circle
        .fa-4x(style="color:#fff").align-self-center
          font-awesome-icon(:icon="['fas', 'calendar-alt']")
      h2.mt-3 {{ $t("customer-data.headline1") }}
      h4 {{ $t("customer-data.headline2") }} {{ voucher.Id }}
      h5.font-weight-bold.my-5(v-if="parentVoucher") {{ $t("constants.mainVoucherInfo").toString().replace("voucherId", parentVoucher.Id) }}

  .row.mt-3
    .col-12
      h5 {{ $t("hyperGuest.yourServices") }}:
      .card.rounded-0
        .card-body
          .row
            .col-12.col-lg-6
              img.img-fluid(:src="productImage" v-if="productImage")
              img.img-fluid(v-else :src="`${publicPath}images/bg-image.jpg`" alt="")
            .col-12.col-lg-6
              p(v-if="voucher.Arrangement") {{ voucher.Arrangement }}
              h5 {{ $t("customer-data.services") }}
              ul
                li(v-for="holidayService in holidayServices" v-if="holidayService.trim()") {{ holidayService.trim() }}

              template(v-if="additionalHolidayServices && Array.isArray(additionalHolidayServices) && additionalHolidayServices.length")
                h5(style="font-style: italic;") {{ $t("customer-data.additionalServices") }}
                ul
                  li(v-for="additionalHolidayService in additionalHolidayServices" v-if="additionalHolidayService.trim()") {{ additionalHolidayService.trim() }}

  .row.mt-3
    .col-12
      h5 {{ $t("hyperGuest.chooseArrivaldate") }}:
      booking-date-picker(
        :lang="lang"
        :disabled-spans="disabledSpans"
        :change-date="changeDate"
        :select-date="selectDate"
      )

  .row.mt-3(v-if="rate")
    .col-12
      h5 {{ $t("hyperGuest.additionalInformation") }}:
      .card.rounded-0
        .card-body
          .row
            .col-12.mt-2
              template(v-if="rate.remarks && Array.isArray(rate.remarks) && rate.remarks.length")
                h5 {{ $t("hyperGuest.info") }}
                p(v-for="remark in rate.remarks") {{ remark }}
              template(v-if="fees && Array.isArray(fees) && fees.length")
                h5 {{ $t("hyperGuest.fees") }}
                p(v-for="fee in fees" v-if="fees") {{ fee.description }}
              template(v-if="taxes && Array.isArray(taxes) && taxes.length")
                h5 {{ $t("hyperGuest.taxes") }}
                p(v-for="tax in taxes") {{ tax.description }}
              template(v-if="rate.cancellationPolicies && Array.isArray(rate.cancellationPolicies) && rate.cancellationPolicies.length")
                h5 {{ $t("hyperGuest.cancellationPolicies") }}
                p(v-for="cancellationPolicy in rate.cancellationPolicies") {{ getCancellationPolicyText(cancellationPolicy) }}

  .row.mt-3(v-if="guests && Array.isArray(guests) && guests.length")
    .col-12
      h5 {{ $t("customer-data.section-headline1") }}
      .card.rounded-0
        .card-body
          .row
            .col-12
              hyperguest-guests(
                v-if="addressCountries && Array.isArray(addressCountries) && addressCountries.length"
                :guests="guests"
                :address-countries="addressCountries"
              )
      p.mt-2 {{ $t("customer-data.section-text") }}

  .row
    .col-12.text-right.mb-3.mt-sm-3
      button.button.button-primary.button-tbook(@click="save") {{ $t("hyperGuest.bookHoliday") }}

</template>

<script>
import EventBus from "@/event-bus";
import { de, en } from "vuejs-datepicker/dist/locale";
import { HostImages } from "@/graphql/hostImages/HostImages.ts";
import { AddressCountries } from "@/graphql/addressCountries/AddressCountries.ts";
import moment from "moment";
import ExclusionPeriodsComponent from "@/components/exclusionPeriods/ExclusionPeriodsComponent";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import { EmailValidatorComponent } from "@/components/emailValidator/EmailValidatorComponent.ts";
import HyperGuestComponent from "../../../components/hyperGuest/HyperGuestComponent";
import BookingDatePicker from "../../components/datepicker/BookingDatePicker.vue";
import VoucherCards from "@/views/partials/vouchers/VoucherCards.vue";
import HyperguestGuests from "@/views/hyperGuest/partials/HyperguestGuests.vue";

export default {
  name: "HyperguestBookingData",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
  },
  components: {
    HyperguestGuests,
    VoucherCards,
    BookingDatePicker,
  },
  data() {
    return {
      bookingDate: undefined,
      hostImages: [],
      publicPath: process.env.BASE_URL,
      lang: localStorage.localeSettings == "en" ? en : de,
      disabledSpans: {
        ranges: [
          {
            from: moment().subtract(100, "years").toDate(),
            to: moment().add(100, "years").toDate(),
          },
        ],
        days: [],
        dates: [],
      },
      currentDate: moment().startOf("month").toDate(),
      addressCountries: [],

      guests: [],
      rate: undefined,

      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  async mounted() {
    try {
      EventBus.$on(
        "changeLanguage",
        function (language) {
          this.lang = language == "en" ? en : de;
        }.bind(this)
      );
      this.handleGuests();
      this.loadAddressCountries();
      this.loadHostImages();
      this.loadExclusionPeriods();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    getCancellationPolicyText(cancellationPolicy) {
      try {
        let cancellationPolicyText = this.$t(
          "hyperGuest.cancellationPolicy"
        ).toString();
        cancellationPolicyText = cancellationPolicyText.replace(
          "daysBefore",
          cancellationPolicy.daysBefore
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          "cancellationDeadlineHour",
          cancellationPolicy.cancellationDeadlineHour
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          "amount",
          cancellationPolicy.amount
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          "penaltyType",
          cancellationPolicy.penaltyType
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          " percent",
          "%"
        );
        return cancellationPolicyText;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async loadHostImages() {
      try {
        const hostImages = await this.$apollo
          .query({
            query: HostImages.Queries.HostImagesByProductId,
            variables: {
              productId: this.voucher.ProductId,
            },
          })
          .then(({ data }) => data?.hostImagesByProductId)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!hostImages) {
          return;
        }
        this.hostImages = hostImages;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadExclusionPeriods() {
      try {
        if (this.parentVoucher) {
          return;
        }
        EventBus.$emit("changeCalendarLoading", true);
        const exclusionPeriodsComponent = new ExclusionPeriodsComponent();
        const exclusionPeriods =
          await exclusionPeriodsComponent.getExclusionPeriods(
            this.$apollo,
            this.voucher.Id,
            this.from,
            this.to
          );
        EventBus.$emit("changeCalendarLoading", false);
        if (!exclusionPeriods) {
          return;
        }
        const exclusionDays = [];
        for (const exclusionDay of exclusionPeriods.ExclusionDays) {
          exclusionDays.push(new Date(exclusionDay));
        }
        if (exclusionPeriods.ExclusionSpans) {
          exclusionPeriods.ExclusionSpans.forEach((e) => {
            e.from = new Date(e.from);
            e.to = new Date(e.to);
          });
        }
        this.disabledSpans = {
          ranges: exclusionPeriods.ExclusionSpans,
          days: exclusionPeriods.ExclusionWeekDays,
          dates: exclusionDays,
        };
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddressCountries() {
      try {
        const addressCountries = await this.$apollo
          .query({
            query: AddressCountries.Queries.AddressCountries,
          })
          .then(({ data }) => data?.addressCountries)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!addressCountries) {
          return;
        }
        this.addressCountries = addressCountries;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async save() {
      try {
        const validatedBookingData = this.validateBookingData();
        if (!validatedBookingData) {
          return this.$alert("Die Buchungsdaten konnte nicht geprüft werden.");
        }
        if (!validatedBookingData.IsSuccess) {
          return this.$alert(validatedBookingData.Message);
        }
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie Ihre Daten absenden möchten?"
        )
          .then()
          .catch(() => {});
        if (!confirmed) {
          return;
        }
        const result = await this._createHyperGuestBooking();
        if (!result) {
          return this.$alert(
            "Die Buchung konnte nicht gespeichert werden, bitte versuchen Sie es später erneut."
          );
        }
        if (result.Message) {
          this.$alert(result.Message);
        }
        if (result.IsSuccess) {
          this.loadVoucher();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _createHyperGuestBooking() {
      try {
        const hyperGuestComponent = new HyperGuestComponent();
        EventBus.$emit("changeLoadingState", true);
        const createdBooking = await hyperGuestComponent.createBooking(
          this.$apollo,
          this.voucher.Id,
          this.bookingDate,
          this.guests
        );
        EventBus.$emit("changeLoadingState", false);
        if (!createdBooking) {
          return {
            IsSuccess: false,
            Message: "Die Buchung konnte nicht gespeichert werden.",
          };
        }
        return createdBooking;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    validateBookingData() {
      try {
        if (!this.bookingDate) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.dataNotFilled").toString(),
          };
        }
        if (!this.isValidDate(this.bookingDate)) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.dataNotCorrect").toString(),
          };
        }
        for (const guest of this.guests) {
          debugger;
          if (Object.keys(guest?.contact).length > 1) {
            // VALIDATION FOR LEADING GUEST
            if (!guest.title) {
              return {
                IsSuccess: false,
                Message: this.$t(
                  "customer-data.alerts.formOfAddress"
                ).toString(),
              };
            }
            if (!guest.name?.first) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.firstName").toString(),
              };
            }
            if (!guest.name?.last) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.lastName").toString(),
              };
            }
            if (!guest.contact?.address) {
              return {
                IsSuccess: false,
                Message: this.$t(
                  "customer-data.alerts.streetAndNumber"
                ).toString(),
              };
            }
            if (!guest.contact?.zip) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.zipCode").toString(),
              };
            }
            if (!guest.contact?.city) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.city").toString(),
              };
            }
            if (!guest.contact?.country) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.country").toString(),
              };
            }
            if (!guest.contact?.email) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.email").toString(),
              };
            }
            const emailValidatorComponent = new EmailValidatorComponent();
            const isValidEmail = emailValidatorComponent.validateEmail(
              guest.contact?.email
            );
            if (!isValidEmail) {
              return {
                IsSuccess: false,
                Message: this.$t(
                  "customer-data.alerts.mailsNotValid"
                ).toString(),
              };
            }
            if (!guest.birthDate) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.birthDate").toString(),
              };
            }
            if (!guest.contact?.phone) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.phone").toString(),
              };
            }
          } else {
            if (!guest.name?.first) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.firstName").toString(),
              };
            }
            if (!guest.name?.last) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.lastName").toString(),
              };
            }
            if (!guest.birthDate) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.birthDate").toString(),
              };
            }
            if (!guest.contact?.country) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.country").toString(),
              };
            }
          }
        }
        return {
          IsSuccess: true,
        };
      } catch (e) {
        console.error(e);
        return {
          IsSuccess: false,
          Message: e.message,
        };
      }
    },
    selectDate(date) {
      try {
        this.bookingDate = date;
        this.loadRate();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    isValidDate(date, checkFutureDates) {
      try {
        if (!date) {
          return false;
        }
        if (date.toString() == "Invalid Date") {
          return false;
        }
        if (date instanceof Date && isNaN(date)) {
          return false;
        }
        let isValid = moment(date).isValid();
        if (!isValid) {
          return false;
        }
        const _date = new Date(date);
        if (Object.prototype.toString.call(_date) === "[object Date]") {
          if (isNaN(_date.getTime())) {
            return false;
          }
        } else {
          return false;
        }
        if (moment(date).year() < 1900) {
          return false;
        }
        if (moment(date).year() > moment().add(5, "years").year()) {
          return false;
        }
        if (!checkFutureDates) {
          return true;
        }
        if (moment(date).year() > moment().year()) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    changeDate(date) {
      try {
        if (date.year) {
          return;
        } else if (date.month) {
          this.currentDate = moment().month(date.month).toDate();
        } else if (moment(date).isValid()) {
          this.currentDate = date;
        }
        if (
          !this.host.ChannelPropertyTypeId ||
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook
        ) {
          return;
        }
        this.loadExclusionPeriods();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadRate() {
      try {
        if (
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.HyperGuest
        ) {
          const hyperGuestComponent = new HyperGuestComponent();
          EventBus.$emit("changeLoadingState", true);
          const rate = await hyperGuestComponent.getRate(
            this.$apollo,
            this.voucher.Id,
            this.bookingDate
          );
          EventBus.$emit("changeLoadingState", false);
          if (!rate) {
            return this.$alert(
              `Es konnte keine Rate für ${moment(this.bookingDate).format(
                "DD.MM.YYYY"
              )} geladen werden.`
            );
          }
          this.rate = rate;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    handleGuests() {
      try {
        if (!this.product?.Personen) {
          return;
        }
        this.guests = [];
        for (let i = 0; i < this.product.Personen; i++) {
          const guest = {
            birthDate: undefined,
            contact: {
              country: "DE",
            },
            name: {
              first: "",
              last: "",
            },
          };
          // FOR LEADING GUEST OF BOOKING
          if (i == 0) {
            guest.contact = {
              address: "",
              city: "",
              country: "DE",
              email: "",
              phone: "",
              zip: "",
              state: "N/A",
            };
            guest.title = "MR";
          }
          this.guests.push(guest);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    fees() {
      try {
        if (!this.rate || !this.rate.prices) {
          return undefined;
        }
        return this.rate.prices?.fees;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    taxes() {
      try {
        if (!this.rate || !this.rate.prices) {
          return undefined;
        }
        let prices = this.rate.prices[this.rate?.payment?.charge];
        if (!prices) {
          prices = this.rate?.prices["sell"];
        }
        return prices?.taxes;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    holidayServices() {
      try {
        if (!this.voucher) {
          return [];
        }
        if (!this.voucher.HolidayServices) {
          return [];
        }
        const holidayServices = this.voucher.HolidayServices;
        if (!holidayServices) {
          return [];
        }
        return this.voucher.HolidayServices.split("\n");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    additionalHolidayServices() {
      try {
        if (!this.voucher) {
          return [];
        }
        if (!this.voucher.AdditionalHolidayServices) {
          return [];
        }
        const holidayServices = this.voucher.AdditionalHolidayServices;
        if (!holidayServices) {
          return [];
        }
        return this.voucher.AdditionalHolidayServices.split("\n");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    productImage() {
      try {
        try {
          const articleImage = this.hostImages.find(
            (i) =>
              i.Image.ImageTypeId == 6 ||
              i.Image.ImageName.toLowerCase().includes("artikelbild")
          );
          if (!articleImage) {
            return undefined;
          }
          return articleImage.RemoteBaseUrl + "large.jpg";
        } catch (e) {
          console.error(e);
          this.$alert(e.message);
          return undefined;
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    from() {
      try {
        return moment(this.currentDate).startOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    to() {
      try {
        return moment(this.currentDate).endOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
