const { HyperGuest } = require("../../graphql/hyperGuest/HyperGuest.ts");

class HyperGuestComponent {
  async createBooking(apollo, voucherId, bookingDate, guests) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!voucherId) {
        return {
          IsSuccess: false,
          Message: "Keine Gutschein-Id gefunden.",
        };
      }
      if (!bookingDate) {
        return {
          IsSuccess: false,
          Message: "Kein Buchungsdatum gefunden.",
        };
      }
      if (!guests || !Array.isArray(guests) || !guests.length) {
        return {
          IsSuccess: false,
          Message: "Keine Gastdaten gefunden.",
        };
      }
      const createdHyperGuestBooking = await apollo
        .mutate({
          mutation: HyperGuest.Mutations.CreateBooking,
          fetchPolicy: "no-cache",
          variables: {
            voucherId: voucherId,
            bookingDate: bookingDate,
            guests: guests,
          },
        })
        .then(({ data }) => data?.createBooking)
        .catch((e) => {
          console.error(e);
          return {
            IsSuccess: false,
            Message: e.message,
          };
        });
      if (!createdHyperGuestBooking) {
        return {
          IsSuccess: false,
          Message: "Die Buchung konnte nicht gespeichert werden.",
        };
      }
      return createdHyperGuestBooking;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }

  async getRate(apollo, voucherId, date) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!voucherId) {
        return undefined;
      }
      if (!date) {
        return undefined;
      }
      const rate = await apollo
        .query({
          query: HyperGuest.Queries.Rate,
          fetchPolicy: "no-cache",
          variables: {
            voucherId: voucherId,
            date: date,
          },
        })
        .then(({ data }) => data?.rate)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!rate) {
        return undefined;
      }
      return rate;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = HyperGuestComponent;
