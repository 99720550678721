<template lang="pug">
.row.mt-5
  .col-12.text-center
    .background-circle
      .fa-4x(style="color:#fff").align-self-center
        font-awesome-icon(:icon="['fas', 'paper-plane']", style="padding-right: 10px;")
    h2.mt-3 {{ $t("booking-requested.headline") }}
    h4 {{ $t("constants.headline") }} {{ voucher.Id }}

  .col-12.mt-3
    .table-responsive
      table.table.table-borderless
        thead.table-head
          tr
            th(scope="col")
              b {{ $t("constants.ticketNumber") }}&nbsp;{{ bookingTicket.Id }}
            th(scope="col")
              b {{ $t("constants.requestedOn") }}&nbsp;{{ bookingTicket.CreatedAt | formatDate }}
            th(scope="col")
              b(v-if="parentVoucher") {{ $t("constants.mainVoucher") }}:&nbsp;{{ parentVoucher.Id }}
              b(v-else-if="voucherIds && Array.isArray(voucherIds) && voucherIds.length") {{ $t("constants.additionalVouchers") }}:&nbsp;{{ voucherIds }}
        tbody(v-if="bookingDateRequests && Array.isArray(bookingDateRequests) && bookingDateRequests.length")
          tr(style="line-height: 20px;")
            td
              b {{ $t("constants.travelPeriod") }}
            td
              b {{ $t("constants.status") }}
            td
          tr(v-for="bookingDateRequest in bookingDateRequests")
            td {{ bookingDateRequest.Date | formatDate }} {{ $t("constants.until") }} {{ moment(bookingDateRequest.Date).add(product.Uebernachtungen, "days") | formatDate }}
            td(style="font-weight:700; color: #777777") {{ $t("booking-requested.status") }}

  .col-12.mt-3
    p(v-if="bookingRequest.Title == 'Herr'") {{ $t("constants.AddressM") }} {{ bookingRequest.LastName }},
    p(v-else) {{ $t("constants.AddressF") }} {{ bookingRequest.LastName }},
    p {{ $t("booking-requested.text") }}
    p.mb-0 {{ $t("constants.closing") }}
    img(:src='$t("constants.touriBookTeam", { publicPath: publicPath })', style="height: 30px")

</template>

<script>
const moment = require("moment");

export default {
  name: "TouribookBookingRequested",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    voucherIds: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,
    };
  },
};
</script>

<style scoped></style>
