<template lang="pug">
.container-fluid
  .row
    .col-12(v-if="voucher && product && host")
      .content-wrapper
        hyperguest-booking-data(
          v-if="!bookingTicket"
          :voucher="voucher"
          :booking-request="bookingRequest"
          :product="product"
          :host="host"
          :load-voucher="loadVoucher"
        )

</template>

<script>
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import HyperguestBookingData from "@/views/hyperGuest/partials/HyperguestBookingData.vue";

export default {
  name: "HyperguestHome",
  components: { HyperguestBookingData },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    // FUNCTIONS
    loadVoucher: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      bookingDateRequests: [],

      BookingTicketStatusEnum: BookingTicketStatusEnum,
    };
  },
  watch: {
    bookingTicket() {
      try {
        if (!this.bookingTicket?.Id) {
          return;
        }
        console.log("load booking");
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
