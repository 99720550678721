<template lang="pug">
.row
  .col-12
    h5 {{ $t('hyperGuest.contactDetailsGuest') }}&nbsp;{{ index + 1 }}:
    .card
      .card-body
        .row
          template(v-if="!isLeadGuest")
            .col-12.col-md-3.col-sm-6
              .form-group
                label.form-label {{ $t("customer-data.firstName") }}
                input.form-control(:placeholder="this.$t('customer-data.firstNamePlaceholder')" v-model="guest.name.first" )
            .col-12.col-md-3.col-sm-6
              .form-group
                label.form-label {{ $t("customer-data.lastName") }}
                input.form-control(:placeholder="this.$t('customer-data.lastNamePlaceholder')" v-model="guest.name.last")
            .col-12.col-md-3.col-sm-6
              .form-group
                label.form-label {{ $t("customer-data.dateOfBirth") }}*
                input.form-control(type="date" :placeholder="this.$t('customer-data.datePlaceholder')" :value="guest.birthDate | formatInputDate" @change="guest.birthDate = $event.target.value;")
            .col-12.col-md-3.col-sm-6
              .form-group
                label.form-label {{ $t("customer-data.country") }}
                select.form-control(v-model="guest.contact.country")
                  option(:value="addressCountry.ISO31661alpha2" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}
          template(v-else)
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.formOfAddress") }}
                select.form-control(v-model="guest.title")
                  option(value="MR") {{ $t("customer-data.gender.m") }}
                  option(value="MS") {{ $t("customer-data.gender.f") }}
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.firstName") }}
                input.form-control(:placeholder="this.$t('customer-data.firstNamePlaceholder')" v-model="guest.name.first" )
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.lastName") }}
                input.form-control(:placeholder="this.$t('customer-data.lastNamePlaceholder')" v-model="guest.name.last")
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.streetAndNumber") }}
                input.form-control(:placeholder="this.$t('customer-data.streetAndNumberPlaceholder')" v-model="guest.contact.address")
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.zipCode") }}
                input.form-control(:placeholder="this.$t('customer-data.zipCodePlaceholder')" v-model="guest.contact.zip")
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.city") }}
                input.form-control(:placeholder="this.$t('customer-data.cityPlaceholder')" v-model="guest.contact.city")
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.country") }}
                select.form-control(v-model="guest.contact.country")
                  option(:value="addressCountry.ISO31661alpha2" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}

            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.guestMail") }}
                input.form-control(:placeholder="this.$t('customer-data.guestMailPlaceholder')" v-model="guest.contact.email")
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.phone") }}*
                input.form-control(:placeholder="this.$t('customer-data.phonePlaceholder')" v-model="guest.contact.phone")
            .col-12.col-md-4
              .form-group
                label.form-label {{ $t("customer-data.dateOfBirth") }}*
                input.form-control(type="date" :placeholder="this.$t('customer-data.datePlaceholder')" :value="guest.birthDate | formatInputDate" @change="guest.birthDate = $event.target.value;")

</template>

<script>
export default {
  name: "HyperguestGuest",
  props: {
    guest: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    addressCountries: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isLeadGuest() {
      try {
        return Object.keys(this.guest?.contact).length > 1;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
