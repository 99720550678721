export class EmailValidatorComponent {
    validateEmail(email) {
        try {
            if (!email) {
                return false;
            }
            const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            return re.test(String(email).trim().toLowerCase());
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}
