import gql from "graphql-tag";

export const HyperGuest = {
    Mutations: {
        CreateBooking: gql`
        mutation ccreateBooking($voucherId: ID, $bookingDate: Date, $guests: [JSON]) {
            createBooking(voucherId: $voucherId, bookingDate: $bookingDate, guests: $guests) {
                IsSuccess
                Message
            }
        }`,
    },
    Queries: {
        Rate: gql`
        query rate($voucherId: ID, $date: Date) {
            rate(voucherId: $voucherId, date: $date)
        }`,
    }
};
