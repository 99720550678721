<template lang="pug">
.row.mt-5
  .col-12.text-center
    .background-circle
      .fa-4x(style="color:#fff").align-self-center
        font-awesome-icon(:icon="['fas', 'paper-plane']", style="padding-right: 10px;")
    h2.mt-3 {{ $t("booking-cancel-request.headline") }}
    h4 {{ $t("constants.headline") }} {{ voucher.Id }}

  .col-12.mt-3
    .table-responsive
      table.table.table-borderless.d-table.w-100.border
        thead.table-head
          tr
            th(scope="col")
              b {{ $t("constants.ticketNumber") }}&nbsp;{{ bookingTicket.Id }}
            th(scope="col")
              b {{ $t("constants.requestedOn") }}&nbsp;{{ bookingTicket.CreatedAt | formatDate }}
            th(scope="col")
              b(v-if="parentVoucher") {{ $t("constants.mainVoucher") }}:&nbsp;{{ parentVoucher.Id }}
              b(v-else-if="voucherIds && Array.isArray(voucherIds) && voucherIds.length") {{ $t("constants.additionalVouchers") }}:&nbsp;{{ voucherIds }}
        tbody
          tr(style="line-height: 20px;")
            td
              b {{ $t("constants.travelPeriod") }}
            td
              b {{ $t("constants.status") }}
            td
          tr
            td(v-if="cancelRequestedBookingDateRequest") {{ cancelRequestedBookingDateRequest.Date | formatDate }} {{$t("constants.until")}} {{ moment(cancelRequestedBookingDateRequest.Date).add(product.Uebernachtungen, "days").toDate() | formatDate }}
            td(style="font-weight:700; color: #777777") {{ $t("booking-cancel-request.status") }}

  .col-12.mt-3
    p(v-if="bookingRequest && bookingRequest.Title == 'Herr'") {{$t("constants.AddressM")}} {{bookingRequest.LastName}},
    p(v-else) {{ $t("constants.AddressF") }} {{ bookingRequest.LastName }},
    p {{ $t("booking-cancel-request.text") }}
    p(v-if="parentVoucher") {{ $t("constants.mainVoucherInfo").toString().replace("voucherId", parentVoucher.Id) }}
    p.mb-0 {{ $t("constants.closing") }}
    img(:src='$t("constants.touriBookTeam", {publicPath: publicPath})', style="height: 30px")

</template>

<script>
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
const moment = require("moment");

export default {
  name: "TouribookCancelRequested",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    voucherIds: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,
    };
  },
  computed: {
    cancelRequestedBookingDateRequest() {
      try {
        if (!this.bookingDateRequests) {
          return undefined;
        }
        const bookingDateRequests = this.bookingDateRequests;
        if (!bookingDateRequests) {
          return undefined;
        }
        const cancelRequestedBookingDateRequest = bookingDateRequests.find(
          (r) =>
            r.BookingDateRequestStatusId ==
            BookingDateRequestStatusEnum.DateCancelRequested
        );
        if (!cancelRequestedBookingDateRequest) {
          return undefined;
        }
        return cancelRequestedBookingDateRequest;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
